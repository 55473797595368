import './FaqPart.scss'

import React, { FunctionComponent, useState } from 'react'
import { Collapse } from 'react-collapse'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxIconBuilder from '../../PuxIconBuilder/PuxIconBuilder'

export interface FaqPartType {
  contentType: 'EasySoftwareFaq'
  contentItemId: string
  displayText: string
  createdUtc: string
  faqAnswer: PuxWysiwygHtmlBodyType
}

const rootClass = `faq-part`

const FaqPart: FunctionComponent<FaqPartType> = (props) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className={`pux-repeater-item ${rootClass}-wrapper`}>
      <div className={`${rootClass}-item`}>
        <div
          className={`${rootClass}-question${isOpened ? ` is-opened` : ``}`}
          onClick={() => setIsOpened(!isOpened)}
        >
          <PuxIconBuilder icon={`ChevronIcon`} />
          <h2>{props.displayText}</h2>
        </div>
        <Collapse isOpened={isOpened}>
          <div className={`${rootClass}-answer`}>
            <PuxWysiwyg content={props.faqAnswer} inline />
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export default FaqPart
